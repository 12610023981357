import { useEffect, useState } from "react"
import { Box, Button, Container, Divider, Grid, IconButton, Link, MenuItem, Paper, TextField, ThemeProvider, Typography, useTheme } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle, faCircleDot, faEnvelope, faMapMarkerAlt, faPhone, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faFacebookF, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

import img_contactUs from '../../assets/images/contactUs7.png';
import { useTranslation } from "react-i18next";
import { enviarCorreo } from "../../email/sendEmail";

//import { SendEmailFromContactUsForm } from "../../controllers/AuxController";
//import QUDProgress from "../../components/progress";
//import ResponseComponent from "../../components/responseComponent";

const style={
    "& .MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error)": {
        border:'solid',
        borderWidth:'1px',
        borderColor: "secondary.main",
        color:'secondry.contrastText',
      },
    "& .MuiInputLabel-root:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error)": {
        color: "#FFF",
        bgcolor:'primary.dark',
        pl:1,pr:1,
    },
    "& .MuiInputBase-input": {
        color: "secondary.main",
    },
}


export default function ContactUs(props)
{
    const theme = useTheme();
    const {t, i18n} = useTranslation();

    const [name,setName] = useState('');
    const [errorName,setErrorName] = useState(false);

    const [position,setPosition] = useState('');
    const [errorPosition,setErrorPosition] = useState(false);
    const [phoneNumber,setPhoneNumber] = useState('');
    const [email,setEmail] = useState('');
    const [errorEmail,setErrorEmail] = useState(false);

    const [companyName,setCompanyName] = useState('');
    const [errorCompanyName,setErrorCompanyName] = useState(false);

    const [country,setCountry] = useState('');
    const [errorCountry,setErrorCountry] = useState(false);

    const [vesselName,setVesselName] = useState('');

    const [needQuotation,setNeedQuotation] = useState('');
    const [grt,setGrt] = useState('');
    const [dwt,setDwt] = useState('');
    const [loa,setLoa] = useState('');

    const [message,setMessage] = useState('');
    const [errorMessage,setErrorMessage] = useState(false);



    const [isLoadingData,setIsLoadingData] = useState(false);
    /*const [title,setTitle] = useState('');
    const [message,setMessage] = useState('');
    const [success,setSuccess] = useState(true);
    const [show,setShow] = useState(false);
    const [actionText,setActionText] = useState('Finalizar');*/

    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);


    const formVerification = () => {
        var ban = 0;
        
        if(name === '')
        {
            setErrorName(true);
            ban = 1;
        }

        if(position === '')
        {
            setErrorPosition(true);
            ban = 1;
        }

        if(email === '')
        {
            setErrorEmail(true);
            ban = 1;
        }

        
        
        return ban;
    }

    const handleSubmit = async () => {
        try
        {
            let response = await fetch(
                'https://api.qud.life/api/v1/contactUsFormShippingCompany',
                {
                    method : 'POST',
                    headers : 
                    {
                        'Content-Type': 'application/json',
                        'Accept' : 'application/json',
                    },
                    body : JSON.stringify({
                        name,position,phoneNumber,email,companyName,country,vesselName,
                        needQuotation,grt,dwt,loa,message
                    })
                }
            );

            let responseJson = await response.json();
            console.log('POST_JSON RESPONSE ===> ',responseJson)
        }
        catch(error)
        {
            
        }
    }

    const handleSuccess = () => {

        setName('');            setErrorName(false);
        setPosition('');        setErrorPosition(false);
        setPhoneNumber('');           
        setEmail('');           setErrorEmail(false);
        setCompanyName('');      setErrorCompanyName(false);
        setCountry('');         setErrorCountry(false);
        setVesselName('');      
        setNeedQuotation('');
        setGrt('');
        setDwt('');
        setLoa('');
        setMessage('');         setErrorMessage(false);

    }

    return(
        <Box sx={{overflowX:'hidden',bgcolor:'#FFF'}}>
            <Box
                sx={{
                    backgroundImage: `url(${img_contactUs})`,
                    backgroundRepeat: 'no-repeat', // Opcional
                    backgroundSize: 'cover', // Opcional
                    backgroundPosition:'center',
                    objectFit:'contain',
                    height: '70vh', // Altura del componente
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    overflowX:'hidden'
                }}
            >
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={8} lg={5} xl={5}>
                            <Typography variant="h3" fontWeight={'bold'} color={'primary.contrastText'} gutterBottom>
                                {t("contactUs.title")}
                            </Typography>
                            <Typography variant="h6" color={'primary.contrastText'} gutterBottom>
                                {t("contactUs.text")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container sx={{pb:5}}>
                <Grid container spacing={3} mt={5} alignItems={'center'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant='h4' color={'primary.dark'} fontWeight={'bold'} textAlign={'center'} textTransform={'uppercase'} gutterBottom>
                            {t("contactUs.button")}
                        </Typography>
                    </Grid>

                    {/**Column 1 */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Paper elevation={4} sx={{bgcolor:'primary.dark',color:'primary.contrastText',pt:5,pb:5}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                                        <FontAwesomeIcon color={theme.palette.secondary.main} icon={faCheckCircle}/>
                                        &nbsp; General Manager
                                    </Typography>
                                    <Typography ml={4} variant="body1" gutterBottom>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                        &nbsp; gerencia@desarrollos1405.com
                                    </Typography>
                                    <Divider sx={{bgcolor:'secondary.main'}}/>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                                        <FontAwesomeIcon color={theme.palette.secondary.main} icon={faCheckCircle}/>
                                        &nbsp; Commercial Department
                                    </Typography>
                                    <Typography ml={4} variant="body1" gutterBottom textTransform={'lowercase'}>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                        &nbsp; DA-ACC@desarrollos1405.com
                                    </Typography>
                                    <Divider sx={{bgcolor:'secondary.main'}}/>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="h6">
                                        OPERATIONS DEPARTMENT 24/7
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                                        <FontAwesomeIcon color={theme.palette.secondary.main} icon={faCheckCircle}/>
                                        &nbsp; Puerto La Cruz
                                    </Typography>
                                    <Typography ml={4} variant="body1" gutterBottom>
                                        <FontAwesomeIcon icon={faPhoneVolume}/>
                                        &nbsp; +58 424-8516126
                                    </Typography>
                                    <Typography ml={4} variant="body1" gutterBottom textTransform={'lowercase'}>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                        &nbsp; operacionesplc@desarrollos1405.com
                                    </Typography>
                                    <Divider sx={{bgcolor:'secondary.main'}}/>
                                </Grid>


                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                                        <FontAwesomeIcon color={theme.palette.secondary.main} icon={faCheckCircle}/>
                                        &nbsp; Puerto Cabello
                                    </Typography>
                                    <Typography ml={4} variant="body1" gutterBottom>
                                        <FontAwesomeIcon icon={faPhoneVolume}/>
                                        &nbsp; +58 412-9440307
                                    </Typography>
                                    <Typography ml={4} variant="body1" gutterBottom textTransform={'lowercase'}>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                        &nbsp; operacionespfj@desarrollos1405.com
                                    </Typography>
                                    <Divider sx={{bgcolor:'secondary.main'}}/>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                                        <FontAwesomeIcon color={theme.palette.secondary.main} icon={faCheckCircle}/>
                                        &nbsp; Amuay
                                    </Typography>
                                    <Typography ml={4} variant="body1" gutterBottom>
                                        <FontAwesomeIcon icon={faPhoneVolume}/>
                                        &nbsp; +58 412-9440307
                                    </Typography>
                                    <Typography ml={4} variant="body1" gutterBottom textTransform={'lowercase'}>
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                        &nbsp; operacionespfj@desarrollos1405.com
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>


                    {/**form */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Grid container spacing={3}>
                           <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="h6" textAlign={'center'} fontWeight={'bold'}>
                                    Let's get in touch today
                                </Typography>
                            </Grid>

                           <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="h6">Personal data</Typography>
                                <Divider/>
                            </Grid>


                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label='Name'
                                    id="name"
                                    name="name"
                                    value={name}
                                    onChange={(e)=>setName(e.target.value)}
                                    onBlur={ () => setErrorName(name === '' ?true:false) }
                                    error = {errorName}
                                    helperText={errorName && 'Enter the name'}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label='Position'
                                    id="position"
                                    name="position"
                                    value={position}
                                    onChange={(e)=>setPosition(e.target.value)}
                                    onBlur={ () => setErrorPosition(position === '' ?true:false)  }
                                    error = {errorPosition}
                                    helperText={errorPosition && 'Enter the position'}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <PhoneInput
                                    inputStyle={{width:'100%'}}
                                    country={'us'}
                                    value={phoneNumber}
                                    onChange={(phoneValue)=>setPhoneNumber(phoneValue)}
                                    //value={this.state.phoneNumber}
                                    //onChange={phoneNumber => this.setState({ phoneNumber })}
                                    
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label='email'
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={(e)=>setEmail(e.target.value)}
                                    onBlur={ () => setErrorEmail(email === '' ?true:false)  }
                                    error = {errorEmail}
                                    helperText={errorEmail && 'Enter the email'}
                                />
                            </Grid>

                            <Grid item lg={12} xl={12} mt={3}>
                                <Typography variant="h6">Company data</Typography>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label='Company Name'
                                    id="companyName"
                                    name="companyName"
                                    value={companyName}
                                    onChange={(e)=>setCompanyName(e.target.value)}
                                    onBlur={ () => setErrorCompanyName(companyName === '' ?true:false)  }
                                    error = {errorCompanyName}
                                    helperText={errorCompanyName && 'Enter the Company Name'}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextField
                                    fullWidth
                                    label='Country'
                                    id="country"
                                    name="country"
                                    value={country}
                                    onChange={(e)=>setCountry(e.target.value)}
                                    onBlur={ () => setErrorCountry(country === '' ?true:false)  }
                                    error = {errorCountry}
                                    helperText={errorCountry && 'Enter the country'}
                                />
                            </Grid>

                           <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    fullWidth
                                    label='Vessel name'
                                    id="vesselName"
                                    name="vesselName"
                                    value={vesselName}
                                    onChange={(e)=>setVesselName(e.target.value)}
                                />
                            </Grid>

                            
                            {/**Optional - if Need a quotation = yes */}
                           <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    fullWidth
                                    select
                                    label='Need a quotation?'
                                    id="needQuotation"
                                    name="needQuotation"
                                    value={needQuotation}
                                    onChange={(e)=>setNeedQuotation(e.target.value)}
                                >
                                     <MenuItem value={'YES'}>YES</MenuItem>
                                     <MenuItem value={'NO'}>NO</MenuItem>
                                </TextField>
                            </Grid>

                            {
                                needQuotation === 'YES' &&
                                <>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            label='GRT'
                                            id="grt"
                                            name="grt"
                                            value={grt}
                                            onChange={(e)=>setGrt(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            label='DWT'
                                            id="dwt"
                                            name="dwt"
                                            value={dwt}
                                            onChange={(e)=>setDwt(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            label='LOA'
                                            id="loa"
                                            name="loa"
                                            value={loa}
                                            onChange={(e)=>setLoa(e.target.value)}
                                        />
                                    </Grid>
                                </>
                            }

                            {/**Message */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextField
                                    color="link"
                                    fullWidth
                                    multiline rows={4}
                                    label='Message'
                                    id="message"
                                    name="message"
                                    value={message}
                                    onChange={(e)=>setMessage(e.target.value)}
                                    onBlur={ () => (message === '' ? setErrorMessage(true) : setErrorMessage(false) ) }
                                    error = {errorMessage}
                                    helperText={errorMessage && 'Ingrese el message'}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'}>
                                <Button
                                    color="primary"
                                    disabled={isLoadingData}
                                    variant="contained"
                                    onClick={()=>handleSubmit()}
                                >
                                    <Typography fontWeight={'bold'}>Send Message</Typography>
                                </Button>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            
        </Box>
    )
}

