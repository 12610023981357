import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";

import img_portada from '../../assets/services.jpg';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import '../../App.css';
import DialogService from "./DialogService";

import image1 from '../../assets/images/2.jpg';
import image2 from '../../assets/images/2.jpg';
import image3 from '../../assets/images/2.jpg';

//Images -> 01-ship agency services and activities
import shipAgency1 from '../../assets/images/services/01-ship agency services and activities/1.png';
import shipAgency2 from '../../assets/images/services/01-ship agency services and activities/2.png';
import shipAgency3 from '../../assets/images/services/01-ship agency services and activities/3.png';
import shipAgency4 from '../../assets/images/services/01-ship agency services and activities/4.png';
import shipAgency5 from '../../assets/images/services/01-ship agency services and activities/5.png';
import shipAgency6 from '../../assets/images/services/01-ship agency services and activities/6.png';

//02
import crewExchange1 from '../../assets/images/services/02-crew exchange logistics medical attention/1.png';
import crewExchange2 from '../../assets/images/services/02-crew exchange logistics medical attention/2.png';
import crewExchange3 from '../../assets/images/services/02-crew exchange logistics medical attention/3.png';
import crewExchange4 from '../../assets/images/services/02-crew exchange logistics medical attention/4.png';
import crewExchange5 from '../../assets/images/services/02-crew exchange logistics medical attention/5.png';
import crewExchange6 from '../../assets/images/services/02-crew exchange logistics medical attention/6.png';
import crewExchange7 from '../../assets/images/services/02-crew exchange logistics medical attention/7.png';

//settlement of port
import settlementPort1 from '../../assets/images/services/07-Settlement of port/1.png';
import settlementPort2 from '../../assets/images/services/07-Settlement of port/2.png';
import settlementPort3 from '../../assets/images/services/07-Settlement of port/3.png';
import settlementPort4 from '../../assets/images/services/07-Settlement of port/4.png';

//03
import maritimeTransport1 from '../../assets/images/services/03-maritime transport/1.png';
import maritimeTransport2 from '../../assets/images/services/03-maritime transport/2.png';
import maritimeTransport3 from '../../assets/images/services/03-maritime transport/3.png';
import maritimeTransport4 from '../../assets/images/services/03-maritime transport/4.png';

//05
import toBePresent1 from '../../assets/images/services/05-to be present as a representative of the importer/4.png';
import toBePresent2 from '../../assets/images/services/05-to be present as a representative of the importer/5.png';
import toBePresent3 from '../../assets/images/services/05-to be present as a representative of the importer/6.png';
import toBePresent4 from '../../assets/images/services/05-to be present as a representative of the importer/7.png';
import toBePresent5 from '../../assets/images/services/05-to be present as a representative of the importer/12.png';

//6
import underWater1 from '../../assets/images/services/06-underwater and diving activities/1.jpg';
import underWater2 from '../../assets/images/services/06-underwater and diving activities/2.jpg';
import underWater3 from '../../assets/images/services/06-underwater and diving activities/3.jpeg';
import underWater4 from '../../assets/images/services/06-underwater and diving activities/4.jpg';
import { useNavigate } from "react-router-dom";

const services_en = [
    {
        title:"Ship agency services and activities",
        list:[
            "Monitoring of port operations.",
            "Managing the clearance process before the different bodies and authorities.",
            "Appointment of ship inspectors or surveyors."
        ],
        images:[shipAgency1,shipAgency2,shipAgency3,shipAgency4,shipAgency5,shipAgency6]
    },
    {
        title:"Settlement of port entry, inbound and outbound fees",
        list:[
            "Management process for the supply of bunkers.",
            "Berthing and unberthing: pilotage, towing and mooring.",
            "Supply of provisions.",
            "Processing for crew discharge or disembarkation.",
            "Notification to SENIAT authorities through the SIDU-NEA system of goods arriving to the national territory Contracting companies for loading and unloading operations, stowage of goods and land transport of goods complementary to maritime transport."
        ],
        images:[settlementPort1,settlementPort2,settlementPort3,settlementPort4]
    },
    {
        title:"Maritime transport",
        list:[
            "Carrying out the formalities related to the contracting or supervision of port cargo handling operations.",
            "Management of maritime brokers (contracting of ships or vessels) for oil or general cargo chartering."

        ],
        images:[maritimeTransport1,maritimeTransport2,maritimeTransport3,maritimeTransport4]
    },
    {
        //title:"Customs Brokerage Services and Activities",
        title:'Crew exchange logistics & medical attention ashore',
        list:[
            "Filing the customs declaration (DUA) of imported or exported goods with customs on behalf of the client.",
            "Request and obtain the services of governmental institutions that certain goods need to pass through customs, such as. Sanitary Control, Phytosanitary Control, etc."

        ],
        images:[crewExchange1,crewExchange2,crewExchange3,crewExchange4,crewExchange5,crewExchange6,crewExchange7]
    },
    {
        title:"To be present, as a representative of the importer or exporter",
        list:[
            "the corresponding physical inspections required by customs.",
            "Guarantee and payment of taxes (VAT and customs duties) on behalf of the importer, before customs.",
            "Appealing against notifications from the Tax Agency on behalf of the represented party.",
            "Advising the economic operator on customs matters."
        ],
        images:[toBePresent1,toBePresent2,toBePresent3,toBePresent4,toBePresent5]
    },
    {
        title:'Underwater and diving activities',
        list:[
            "Within our wide range of services, we have industrial diving services as well as technical support for anti-narcotics inspections",
        ],
        images:[underWater1,underWater2,underWater3,underWater4]
    }
];

const services_es = [
    {
      title: "Servicios y actividades de agencia naviera",
      list: [
        "Monitoreo de las operaciones portuarias.",
        "Gestión del proceso de despacho ante los diferentes organismos y autoridades.",
        "Designación de inspectores o peritos de buques."
      ],
      images: [shipAgency1, shipAgency2, shipAgency3, shipAgency4, shipAgency5, shipAgency6]
    },
    {
      title: "Liquidación de derechos de entrada a puerto, entrada y salida",
      list: [
        "Proceso de gestión para el suministro de bunkers.",
        "Atraque y desatraque: practicaje, remolque y amarre.",
        "Suministro de provisiones.",
        "Trámite para el rol o desembarque de la tripulación.",
        "Notificación a las autoridades del SENIAT a través del sistema SIDU-NEA de las mercancías que arriban al territorio nacional. Contratación de empresas para operaciones de carga y descarga, estiba de la mercancía y transporte terrestre de mercancía complementario al transporte marítimo.",
      ],
      images: [crewExchange1, crewExchange2, crewExchange3, crewExchange4, crewExchange5, crewExchange6]
    },
    {
      title: "Transporte marítimo",
      list: [
        "Realización de los trámites relacionados con la contratación o supervisión de las operaciones portuarias de manipulación de carga.",
        "Gestión de corredores marítimos (contratación de buques o embarcaciones) para fletamento de petróleo o carga general."
      ],
      images: [maritimeTransport1, maritimeTransport2, maritimeTransport3, maritimeTransport4]
    },
    {
      title: "Servicios y Actividades de Agente Aduanal",
      list: [
        "Presentar la declaración aduanera (DUA) de mercancía importada o exportada ante la aduana en nombre del cliente.",
        "Solicitar y obtener los servicios de las instituciones gubernamentales que ciertos bienes necesitan para pasar por la aduana, tales como Control Sanitario, Control Fitosanitario, etc."
      ],
      images: [maritimeTransport1, maritimeTransport2, maritimeTransport3, maritimeTransport4]
    },
    {
      title: "Estar presente, como representante del importador o exportador",
      list: [
        "En las correspondientes inspecciones físicas requeridas por la aduana.",
        "Garantizar y pagar los impuestos (IVA y derechos de aduana) a nombre del importador, ante la aduana.",
        "Recurrir las notificaciones de la Agencia Tributaria en nombre del representado.",
        "Asesorar al operador económico en materia aduanera."
      ],
      images: [toBePresent1, toBePresent2, toBePresent3, toBePresent4, toBePresent5]
    },
    {
      title: "Actividades subacuáticas y de buceo",
      list: [
        "Dentro de nuestra amplia gama de servicios, contamos con servicios de buceo industrial, así como soporte técnico para inspecciones antinarcóticos.",
      ],
      images: [underWater1, underWater2, underWater3, underWater4]
    }
  ];

export default function Services()
{
    const theme = useTheme();
    const [services,setServices] = useState(services_en);
    const {t, i18n} = useTranslation();

    const [open,setOpen] = useState(false);
    const [service,setService] = useState();

    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    useEffect(()=>{
        if(i18n.language==='en')
            setServices(services_en)
        else
            setServices(services_es)
    },[i18n.language]);

    useEffect(()=>{
        const scrollPosition = window.scrollY;
        const disableScrollAdjustment = () => {
          window.scrollTo(scrollPosition, 0);
        };

        const cleanup = () => {
            window.removeEventListener('beforeunload', disableScrollAdjustment);
          };
      
          window.addEventListener('beforeunload', disableScrollAdjustment);
          return cleanup;
    },[open])


    const handleOpenDialogService = (_item) => {
        setService(_item);
        setOpen(true);
    }

    const navigate = useNavigate();


    const redirect = (route) => {
        navigate(route);
    }

    return(
        <div id="Services">
        <Box
            sx={{
                display:'flex',
                flexDirection:{xs:'column-reverse',sm:'column-reverse',md:'row',lg:'row',xl:'row'},
                mt:5,mb:5,
                bgcolor:'primary.dark',
                minHeight:'100vh'
            }}
            
        >
            <Box
                sx={{
                    flex:1,
                    color:'primary.contrastText',
                    display:'flex',
                    flexDirection:'column',
                    minHeight:'100vh',
                    justifyContent:'center'
                }}
            >
                <Container maxWidth={'lg'}
                    sx={{
                        pt:5,
                        pl:{xs:2,sm:4,md:4,lg:12,xl:26},
                        pr:{xs:1,sm:0,md:0,lg:1,xl:2}
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h4" component={'h1'} fontWeight={'bold'} gutterBottom>{t("titles.services")}</Typography>
                            <Typography variant="h6" component={'p'} gutterBottom>{t("services.title")}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} ml={-5}>
                            {
                                services.map((item,index)=>
                                    <List key={index}>
                                        <ListItem>
                                            <ListItemButton onClick={()=>handleOpenDialogService(item)}>
                                                <ListItemIcon>
                                                    <FontAwesomeIcon size="2x" color={theme.palette.secondary.main} icon={faCircleCheck}/>
                                                </ListItemIcon>
                                                <ListItemText primary={item.title} />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                )
                            }
                            <DialogService
                                open={open}
                                handleClose={()=>setOpen(false)}
                                service={service}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{pb:{xs:5}}}>
                            <Button 
                                variant='outlined' color="secondary"
                                sx={{borderColor:'#FFF',color:'#FFF','&:hover':{color:'secondary.main'},mt:2}}
                                onClick={()=>redirect('/contact')}
                            >
                                <Typography variant='h6'>
                                &nbsp;&nbsp; Contact US &nbsp;&nbsp;
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>


            <Box sx={{flex:1,height:'100%'}}>
                <Box
                    sx={{
                        backgroundImage: `url(${img_portada})`,
                        backgroundRepeat: 'no-repeat', // Opcional
                        backgroundSize: 'cover', // Opcional
                        backgroundPosition:'center',
                        minHeight: {lg:'135vh',xl:'112vh'}, // Altura del componente
                        
                        overflowX:'hidden'
                    }}
                />
            </Box>
        </Box>
        </div>
    )
}