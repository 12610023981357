import { forwardRef } from "react";
import { AppBar, Box, Button, Container, Dialog, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Slide, Toolbar, Typography, useTheme } from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faClose } from "@fortawesome/free-solid-svg-icons";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




export default function DialogService(props)
{
    const theme = useTheme();
    return(
        <Dialog
            fullScreen
            open={props.open}
            onClose={props.handleClose}
            TransitionComponent={Transition}
            sx={{padding:0}}
        >
            <AppBar position="fixed"
                sx={{
                    borderRadius:0,
                    paddingTop:1,
                    paddingBottom:1,
                    paddingLeft:1,
                    paddingRight:'0px!important'
                }}
            >
                <Toolbar sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.handleClose}
                        aria-label="close"
                    >
                        <FontAwesomeIcon icon={faClose}/>
                    </IconButton>
                    <Typography variant="h6">
                        {props?.service?.title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth='xl'>
                <Grid container spacing={3} mt={10}>
                    <Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
                        <Carousel
                            autoPlay={true}
                            infiniteLoop={true}
                            width={'100%'}
                        >
                            {
                                props?.service?.images.length !==0 &&
                                props?.service?.images.map(item=>
                                    <div>
                                        <img
                                            src={item}
                                            alt={props?.service?.title}
                                            style={{width:'100%'}}
                                        />
                                    </div>
                                )
                            }
                        </Carousel>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <List>
                            {props?.service?.list.map(item=>
                                <ListItem sx={{pl:0,pr:{xs:0,md:1}}}>
                                    <ListItemButton sx={{pl:0,pr:{xs:0,md:1}}}>
                                        <ListItemIcon>
                                            <FontAwesomeIcon size="2x" color={theme.palette.primary.main} icon={faCircleCheck}/>
                                        </ListItemIcon>
                                        <ListItemText  primary={<Typography variant="h6">{item}</Typography>} />
                                    </ListItemButton>
                                </ListItem>
                                )
                            }
                        </List>
                    </Grid>
                </Grid>
            </Container>
        </Dialog>
    )
}

/**
 * <Box sx={{display:'flex',flexDirection:'row'}}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={props.handleClose}
                        aria-label="close"
                    >
                        <FontAwesomeIcon icon={faClose}/>
                    </IconButton>
                    <Button sx={{ ml: 2,}} variant="body1">
                        {props?.service?.title}
                    </Button>
                </Box>
 */