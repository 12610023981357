import { faArrowDown, faArrowLeft, faArrowRight, faAward, faCheckCircle, faPhoneVolume, faShip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, useTheme } from "@mui/material";

import logo from '../assets/logo.png';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


const wu_en = [
    {
        icon:faPhoneVolume,
        title:'Seamless Communication & Expert Support',
        list:[
            'Real-time updates and dedicated supervision for smooth operations.',
            'Comprehensive logistics to minimize your workload.'
        ]
    },
    {
        icon:faAward,
        title:'Unparalleled Expertise & Quality',
        list:[
            'Seasoned professionals ensure compliance and minimize delays.',
            'Rigorous QMS for consistent quality and best practices.'
        ]
    },
    {
        icon:faShip,
        title:'Tailored Solutions & Commitment to Excellence',
        list:[
            'Customized solutions to meet your specific requirements.',
            'Passionate about providing exceptional service that exceeds expectations.'
        ]
    }
];

const wu_es = [
    {
        icon:faPhoneVolume,
        title:'Comunicación fluida y soporte experto',
        list:[
            'Actualizaciones en tiempo real y supervisión dedicada para operaciones sin problemas.',
            'Logística integral para minimizar su carga de trabajo.'
        ]
    },
    {
        icon:faAward,
        title:'Experiencia y calidad incomparables',
        list:[
            'Profesionales experimentados garantizan el cumplimiento y minimizan los retrasos.',
            'Sistema de Gestión de Calidad riguroso para una calidad constante y las mejores prácticas.'
        ]
    },
    {
        icon:faShip,
        title:'Soluciones a medida y compromiso con la excelencia',
        list:[
            'Soluciones personalizadas para satisfacer sus necesidades específicas.',
            'Apasionados por brindar un servicio excepcional que supere las expectativas.'
        ]
    }
];

export default function WhyUs()
{
    const theme = useTheme();
    const [whyUs,setWhyUs] = useState(wu_en);
    const {t, i18n} = useTranslation();


    useEffect(()=>{
        if(i18n.language==='en')
            setWhyUs(wu_en)
        else
            setWhyUs(wu_es)
    },[i18n.language]);

    return(
        <Container maxWidth='lg' sx={{mt:5,mb:5}}>
            <Grid container spacing={3} pb={5} justifyContent={'center'}>
                <Grid item lg={12} xl={12}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item xs={10} lg={5} xl={5}>
                            <Box sx={{width:'100%'}}>
                                <img
                                    src={logo}
                                    alt="Why us"
                                    style={{width:'100%'}}
                                />
                            </Box>
                        </Grid>
                        <Grid item lg={12} xl={12}>
                            <Grid container spacing={3} justifyContent={'center'}>
                                <Grid item lg={8} xl={8}>
                                    <Typography component={'p'} variant="h4" textAlign={'center'} color={'cancel.dark'}>
                                        {t("whyUs.text")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={12} xl={12}>
                            <Typography component={'h1'} variant="h4" textAlign={'center'} color={'primary.dark'} fontWeight={'bold'}>
                                {t("whyUs.title")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                
                {
                    whyUs.map(item=>
                        <Grid item lg={4} xl={4} mt={5}>
                            <Paper elevation={4} sx={{height:'100%'}}>
                                <Grid container spacing={3} justifyContent={'center'}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} justifyContent={'center'}>
                                        <FontAwesomeIcon size="3x" icon={item.icon}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography component={'h1'} variant="h6" color={'primary.dark'} textAlign={'center'} fontWeight={'bold'}>
                                            {item.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        {
                                            item.list.map(list=>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <FontAwesomeIcon color={theme.palette.secondary.main} icon={faCheckCircle}/>
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography component={'p'}>{list}</Typography>}/>
                                                </ListItem>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )
                }
            </Grid>
        </Container>
    )
}