const lightTheme = {
    palette : 
    {
        common:
        {
            black:'#000',
            white:'#FFF'
        },
        /*primary : 
        {
            light: '#08A9EC',
            main : '#029BDD',
            dark: '#0088CA',
            contrastText:'#FFF'
        },*/
        primary :
        {
            light: '#5e9be0',
            main : '#2969B1',//#0a0a0a  232F3E  1a1a1a
            dark: '#1f5087',
            //contrastText:'#d3d3d3',
            contrastText:'#FFF',
            contrastAux:'#727375'
        },
        secondary:
        {
            light: '#91e0e3',
            main : '#41B9BE',//'#00d0c6', 0288D1 //F33232 //00d0c6
            dark: '#2b888c',
            contrastText : '#FFF',
        },
        link :
        {
            light: '#0599DE',
            lightTransparent: '#e3f6ff',
            main : '#147ac2',//#0a0a0a  232F3E  1a1a1a
            dark: '#0076B6',
            contrastText:'#FFF',
            contrastAux:'#595959'
        },
        error:
        {
            light: '#039BE5',
            main : '#F33232',
            dark: '#01579B',
            contrastText : '#FFF',
        },
        success:
        {
            //lightTransparency: '#f0fff0', //#f5fcf5
            lightTransparency: '#49bf4f15',
            light: '#49bf4f',
            main : '#2E7D32',
            dark: '#215c24',
            contrastText:'#FFF'
        },
        primaryAux :
        {
            light: '#FFF',
            main : '#FFF',//'#00d0c6', 0288D1
            dark: '#FFF',
            contrastText : '#0A0A0A',
        },
        cancel:
        {
            light: '#aebdc2',
            main:'#6e7c80',
            dark: '#435054',
            contrastText : '#000',
        },
        cancelDark:
        {
            light: '##9c9c9c',
            main:'#757575',
            dark: '#454545',
            contrastText : '#000',
        },
        text:
        {
            primary:'#212121',
            secondary:'#474D57',
            primaryTitle:'#EAECEF',
            primaryText:'#B7BDC6',
            link:'#C99400'
        },
        background : 
        {
            default:'#F6F6F6',
            main:'#FFF',
            paper : '#FFF',
            paperDark : '#0B0E11',
            contrastBg:'#FCD535',
            appBar:'#FFF'
        },
        tastyRed:
        {
            
            light:'#FF0B00',
            main:'#BC0901',
            dark: '#850600',
            contrastText:'#FFF',
        },
        tastyMustard:
        {
            light: '#ffbb33',
            main: '#ffab00',
            dark:'#cc8900',
            contrastText: '#FFF'
        },
        usm:
        {
            light: '#1d45ab',
            main: '#192f67',
            dark:'#0c1836',
            contrastText: '#FFF',
            contrastTitle: '#ff9d2b'
        }

    },
    components:
    {
        MuiPaper:
        {
            styleOverrides:{
                root:{
                    borderRadius:10,
                    padding:20,
                    /*"&:hover":{
                        cursor:'pointer'
                    }*/
                },
            }
        },
        MuiInputBase: {
            styleOverrides:{
                root: {
                    
                    "&:hover":{
                        color: "#41B9BE",
                    },
                  
                }  
            },
        },
        MuiInputLabel: {
            styleOverrides:{
                root: {
                    
                    "&:focused": {
                        color: "#41B9BE",
                    }
                  
                }  
            },
        },
        MuiTypography:{
            styleOverrides:{
                root:{
                    //fontWeight: '600'
                },
                body1:{
                    //color:'#454545'
                },
                subtitle1:{
                    color:'#454545'
                }
            }
        },
        MuiButton:{
            styleOverrides:{
                root:{
                    textTransform:'none'
                }
            }
        }
    }
}

export default lightTheme;