import { Container, Grid, List, ListItem, ListItemText, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from 'framer-motion';


function PortName(props)
{
    const [isHovered, setIsHovered] = useState(false);
    const [description,setDescription] = useState(props.description || [])
    return(
        <Paper elevation={4} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <Typography textAlign={'center'} component={'p'} fontWeight={'bold'} textTransform={'uppercase'}>{props.name}</Typography>
            {
                (isHovered && description.length > 0) &&
                <List>
                    {
                        description.map((item,index)=>
                            <ListItem key={index}>
                                <ListItemText primary={`-${item}`}/>
                            </ListItem>
                        )
                    }
                    
                </List>
                
            }
        </Paper>
    )
}
    //<Typography textAlign={'center'}>{props.description}</Typography>

export default function Ports()
{
    const {t, i18n} = useTranslation();

    const [isHovered, setIsHovered] = useState(false);

    const variants = {
        initial: {
          rotateY: 0, // Initial rotation around Y-axis (0 = no rotation)
          transition: { duration: 2, ease: 'linear' }, // Adjust duration and easing as needed
        },
        hover: {
          rotateY: 180, // Full rotation around Y-axis (360 = complete rotation)
          transition: { duration: 2, ease: 'linear' }, // Adjust duration and easing as needed
        },
      };

    const portList = [
        {
            name:"Puerto La Cruz",
            description:['Guaraguao Terminal','Pozuelos Bunkering Zone']
        },
        {
            name:"Jose",
            description:['Offshore Platform TAECJAA','Offshore Monobuoys GFMII & JAA','Pequiven Terminal','Petro San Felix Terminal','Cryogenic Complex Terminal']
        },
        {
            name:"Puerto Cabello",
            description:['El Palito Terminal']
        },
        {
            name:"Amuay",
            description:['Refinery Center Paraguaná Amuay & Cardón Terminals','Offshore STS Zone Caquetios']
        }
    ];

    return(
        <Container maxWidth='lg'>
            <Grid container spacing={3} pb={5}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h4" component={'h1'} fontWeight={'bold'} color={'primary.dark'} gutterBottom>{t("ports.title")}</Typography>
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6" component={'p'} gutterBottom>{t("ports.text")}</Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        {
                            portList.map(item=>
                                <Grid item xs={12} sm={12} md={10} lg={3} xl={3}>
                                    <PortName
                                        name={item.name}
                                        description={item.description}
                                    />
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}