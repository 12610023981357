
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Services from './pages/services';
import { useEffect, useMemo, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import lightTheme from './themes/lightTheme';
import darkTheme from './themes/darkTheme';
import ColorModeContext from './themes/colorModeContext';
import ContactUs from './pages/contactUs';
import Header from './components/Header';


function App() {
	const [mode, setMode] = useState('light');
    const colorMode = useMemo(
        () => ({
        toggleColorMode: () => {
            setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
        }),
        [],
    );

    useEffect(()=>{
        window.scrollTo(0,0);
    })


    const theme = useMemo(
        () => 
        createTheme(
            {
                typography:
                {
                    fontFamily:['koho','sans-serif',].join(','),
                }
            },
            mode === 'light' ? lightTheme : darkTheme,
        )
    )
	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<Routes>
						<Route exact path="/" element={<Header componentUI={<Home/>}/>} />
						<Route path="/about" element={<Header componentUI={<About/>} />}/>
                        <Route path="/contact" element={<Header componentUI={<ContactUs/>}/>} />
						<Route path="/services" element={<Header componentUI={<Services/>}/>} />
					</Routes>
				</BrowserRouter>
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export default App;
