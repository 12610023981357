import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useHref, useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faLanguage } from '@fortawesome/free-solid-svg-icons';

import logo from '../assets/logo.png';

import USA from '../assets/usa.png';
import VE from '../assets/venezuela.png';
import Footer from './Footer';

const drawerWidth = 240;
//const navItems = ['Home','Services', 'About', 'Contact'];

const navItems = [
    {en:'Home',es:'Inicio',route:'/'},
    {en:'Services',es:'Servicios',route:'/services'},
    {en:'About',es:'Nosotros',route:'/about'},
    {en:'Contact Us',es:'Contáctanos',route:'/contact'}
];


function Header(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const href = useHref()

    const handleLanguage = (lang) => {
        //console.log('IDIOMA === ',i18n.language)
        i18n.changeLanguage(lang);
    }

    const handleGoTo = (e,to) => {
        e.preventDefault();
        /*window.scrollTo({
            top: document.querySelector("#"+to).offsetTop,
            behavior: "smooth",
        });*/
        navigate(to);
    }
    

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                <img
                    src={logo}
                    alt='Desarrollos 1405 CA'
                    style={{width:'100%'}}
                />
            </Typography>
            <Divider />
            <List>
                {navItems.map((item,index) => (
                <ListItem key={index} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }} onClick={(e)=>handleGoTo(e,item.route)}>
                        <ListItemText primary={i18n.language === 'en'?item.en:item.es} />
                    </ListItemButton>
                </ListItem>
                ))}
            </List>
            <IconButton onClick={()=>handleLanguage(i18n.language==='es'?'en':'es')}>
                <img src={i18n.language==='es'?VE:USA} alt='language' style={{width:40}}/>
            </IconButton>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav" sx={{borderRadius:0, bgcolor:'#000'}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        <img
                            src={logo}
                            alt='Desarrollos 1405 CA'
                            style={{width:'15%'}}
                        />
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item,index) => 
                            <Button
                                key={item.route}
                                sx={{ color: '#fff' }}
                                onClick={(e)=>handleGoTo(e,`${item.route}`)}
                            >
                                {
                                    i18n.language === 'en'
                                    ? <Typography fontWeight={'bold'}>{item.en}</Typography>
                                    : <Typography fontWeight={'bold'}>{item.es}</Typography>
                                }
                            </Button>
                        )}
                        <Button href='https://desarrollos1405.com/Brochure.pdf' target='_blank'>
                            <Typography fontWeight={'bold'} color={'#FFF'}>Brochure</Typography>
                        </Button>
                        <Button color='secondary' onClick={()=>handleLanguage(i18n.language==='es'?'en':'es')}>
                            <FontAwesomeIcon icon={faLanguage}/>
                            &nbsp;
                            <Typography fontWeight={'bold'}>
                                {i18n.language==='es'?'ES':'EN'}
                            </Typography>
                            
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    >
                    {drawer}
                </Drawer>
            </nav>
            <Box component="main" sx={{ width:'100%',bgcolor:'#FFF'}}>
                <Toolbar />
                {props.componentUI}
                <Footer/>
            </Box>
        </Box>
    );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
