import { Box, Button, Container, Divider, Grid, IconButton, Typography, useTheme } from "@mui/material";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faLinkedin, faTiktok, faWhatsapp } from "@fortawesome/free-brands-svg-icons";



export default function Footer()
{
    const theme = useTheme();
    
    return(
        <Box
            sx={{
                backgroundColor:'primary.dark',
                //minHeight:{xs:'75vh',sm:'95vh',md:'85vh',lg:'75vh',xl:'75vh'},
                pt:2,pb:2
            }}
        >
            <Container maxWidth='xl'>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6" component='p' color='primary.contrastText'>
                            © {moment().format('YYYY')} Desarrollos 1405 CA. Todos los derechos reservados.
                        </Typography>
                        <Button variant="text" sx={{textTransform:'none'}} href="https://technologicalsharks.com/" target="_blank">
                            <Typography variant="body2" component='p' color='primary.contrastText'>
                                Powered By Technological Sharks
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}