import { Box, Button, Chip, Container, Grid, IconButton, Paper, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faAward, faChevronCircleDown, faShip } from "@fortawesome/free-solid-svg-icons";
import { motion, useScroll } from "framer-motion"
import '../App.css';
import Services from "./services";
import Ports from "./ports";
import WhyUs from "./whyUs";

import img_logo from '../assets/logo.png';
import img_portada from '../assets/images/portada.png';
import img_portada2 from '../assets/images/pantalla de inicio 2.jpeg';
import img_contactUs from '../assets/images/contactUs7.png';
import { useNavigate } from "react-router-dom";

export default function Home()
{
    const { scrollYProgress } = useScroll();
    const theme = useTheme();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    

    const handleLanguage = (lang) => {
        //console.log('IDIOMA === ',i18n.language)
        i18n.changeLanguage(lang);
    }

    const redirect = (route) => {
        navigate(route);
    }


    return(
        <>
            
            {/**Hero Section */}
            <Box
                sx={{
                    backgroundImage: `url(${img_portada})`,
                    backgroundRepeat: 'no-repeat', // Opcional
                    backgroundSize: 'cover', // Opcional
                    height: '100vh', // Altura del componente
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    overflowX:'hidden'
                }}
            >
                <Container component={'div'}>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item xs={12} sm={12} md={8} lg={9} xl={9} p={1}>
                            <Box sx={{p:4,backdropFilter:'blur(10px)',bgcolor:'#00000090',borderRadius:10,display:'flex',flexDirection:'column',alignItems:'center'}}>
                                <Box sx={{width:{xs:'80%',sm:'70%',md:'50%',lg:'50%',xl:'50%'}}}>
                                <img
                                    src={img_logo}
                                    style={{width:'100%'}}
                                    alt="Desarrollos 1405 CA"
                                />
                                </Box>

                                {/*<Typography component={'h1'} fontWeight={'bold'} variant='h3' gutterBottom textAlign={'center'} color={'#FFF'} sx={{display:{xs:'none',sm:'block',md:'block',lg:'block',xl:'block'}}}>
                                    {t('hero.hero1')}<br/>{t('hero.hero2')}<br/>{t('hero.hero3')}
                                </Typography>
                                <Typography component={'h1'} fontWeight={'bold'} variant='h4' gutterBottom textAlign={'center'} color={'#FFF'} sx={{display:{xs:'block',sm:'none',md:'none',lg:'none',xl:'none'}}}>
                                    {t('hero.hero1')}<br/>{t('hero.hero2')}<br/>{t('hero.hero3')}
                                </Typography>*/}
                                <Chip
                                    sx={{bgcolor:'#FFFFFF99',mt:2}}
                                    label={<Typography variant="h6" fontWeight={'bold'} color={'primary.main'}>SHIPPING SERVICES</Typography>}
                                />
                                <Box sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                                    <motion.div
                                        animate={{
                                            scale: [1, 2, 2, 1, 1],
                                            rotate: [0, 0, 270, 270, 0],
                                            borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                                        }}

                                        whileHover={{
                                            scale: 1.3,
                                            transition: { duration: 0.5 },
                                        }}
                                    >
                                        <IconButton
                                            onClick={()=>
                                                window.scrollTo({
                                                    top: document.getElementById('start').offsetTop,
                                                    behavior:'smooth'
                                                })
                                            }
                                        >
                                            <FontAwesomeIcon size="2x" icon={faChevronCircleDown}/>
                                        </IconButton>
                                    </motion.div>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/**Home Section */}
            <Container maxWidth='lg' component={'div'} id="start">
                <Grid container spacing={3} mt={5} mb={5} alignItems={'center'}>
                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9} sx={{mt:{xs:10,sm:10,md:12,lg:15,xl:15}}}>
                        <Typography variant="h4" component={'h1'} fontWeight={'bold'} color={'primary.main'} gutterBottom>
                            {t('home.title')}
                        </Typography>
                        <Typography variant="h5" component={'h2'} fontWeight={'bold'} gutterBottom>
                            {t('home.subtitle')}
                        </Typography>
                        <Typography variant="h6" component={'p'} color={'primary.contrastAux'} gutterBottom>
                            {t('home.description')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} display={'flex'} justifyContent={'center'}>
                        <FontAwesomeIcon size="9x" icon={faShip} color={theme.palette.primary.main}/>
                    </Grid>
                </Grid>

                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Paper elevation={4} sx={{display:'flex',flexDirection:'column',alignItems:'center',bgcolor:'primary.dark',color:'primary.contrastText',p:5}}>
                            <Typography variant='h6' fontWeight={'bold'} textAlign={'center'} gutterBottom color={'secondary.light'} textTransform={'uppercase'}>
                                {t("call to action.title")}
                            </Typography>
                            <Typography variant='h6' textAlign={'center'} gutterBottom>
                                {t("call to action.text")}
                            </Typography>
                            <Button variant='contained' color="secondary" sx={{mt:2}} onClick={()=>redirect('/contact')}>
                                <Typography variant='h6'>
                                    &nbsp;&nbsp; {t("call to action.button")} &nbsp;&nbsp;
                                </Typography>
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <Services/>
            <Ports/>
            <WhyUs/>

            {/**Contact Us */}
            <Box
                sx={{
                    backgroundImage: `url(${img_contactUs})`,
                    backgroundRepeat: 'no-repeat', // Opcional
                    backgroundSize: 'cover', // Opcional
                    backgroundPosition:'center',
                    objectFit:'contain',
                    height: '75vh', // Altura del componente
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    overflowX:'hidden'
                }}
            >
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={8} lg={5} xl={5}>
                            <Typography component={'h1'} variant="h3" fontWeight={'bold'} color={'primary.contrastText'} gutterBottom>
                                {t("contactUs.title")}
                            </Typography>
                            <Typography component={'p'} variant="h6" color={'primary.contrastText'} gutterBottom>
                                {t("contactUs.text")}
                            </Typography>
                            <Button
                                variant='outlined'
                                color="secondary"
                                sx={{
                                    borderColor:'#FFF',
                                    color:'#FFF',
                                    '&:hover':{color:'secondary.main'},
                                    mt:2
                                }}
                                onClick={()=>redirect('contact')}
                            >
                                <Typography variant='h6'>
                                    &nbsp;&nbsp; {t("contactUs.button")} &nbsp;&nbsp;
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}