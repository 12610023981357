import { Box, Container, Grid, Typography, useTheme } from "@mui/material";

import img_contactUs from '../assets/images/contactUs7.png';
import { useTranslation } from "react-i18next";

export default function About()
{
    const theme = useTheme();
    const {t, i18n} = useTranslation();

    return(
        <Box sx={{overflowX:'hidden',bgcolor:'#FFF'}}>
            <Box
                sx={{
                    backgroundImage: `url(${img_contactUs})`,
                    backgroundRepeat: 'no-repeat', // Opcional
                    backgroundSize: 'cover', // Opcional
                    backgroundPosition:'center',
                    objectFit:'contain',
                    height: '70vh', // Altura del componente
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    overflowX:'hidden'
                }}
            >
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={8} lg={5} xl={5}>
                            <Typography variant="h3" fontWeight={'bold'} color={'primary.contrastText'} gutterBottom>
                                {t("aboutUs.title")}
                            </Typography>
                            <Typography variant="h6" color={'primary.contrastText'} gutterBottom>
                                {t("aboutUs.text")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container sx={{mt:10}}>
                <Grid container spacing={3} alignItems={'center'}>
                    <Grid item xs={12} sm={12} md={4} lg={6} xl={6} >
                        <Typography variant="h3" color={'primary.main'} fontWeight={'bold'} textAlign={'center'}>{t("aboutUs.misionTitle")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                        <Typography variant="h6" textAlign={'center'}>{t("aboutUs.mision")}</Typography>
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{mt:15,pb:10}}>
                <Grid container spacing={3} alignItems={'center'} display={'flex'} flexDirection={'row-reverse'}>
                    <Grid item xs={12} sm={12} md={4} lg={6} xl={6} >
                        <Typography variant="h3" color={'primary.main'} fontWeight={'bold'} textAlign={'center'}>{t("aboutUs.visionTitle")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                        <Typography variant="h6" textAlign={'center'}>{t("aboutUs.vision")}</Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}